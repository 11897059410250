body{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../public/img/fuji-mountain-with-milky-way-day.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*----------nav bar----------*/
nav { 
  width: 100%;
  padding: 0.5%;
  background-color: #222222;
  font-family: -apple-system;
  text-align: center;
  position: fixed;
  top: 0px;
  z-index: 101
}
nav a{
  text-decoration: none; /*冇底線*/
  padding: 10px 20px;
  border-radius: 0px;
  font-weight: bold;
  color: #ffffff;
}

nav a:visited{
  color: #ffffff;
}

h1{
  font-family: -apple-system;
  font-size: 200%;
  background-color: rgb(240, 204, 2);
  border-top:5px solid #222222;
  border-bottom:5px solid #222222;
  text-align: center;
  margin-top: 38px;
}
/*---------loadingScreen-------*/
.loadingScreen{
  font-family: -apple-system;
  font-size: large;
  text-align: center;
  margin-top: 100px;
}

/*----------container----------*/
.container{
  font-family: -apple-system;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px auto;
  width: 95%;
  max-width: 1020px;
}

.containerItem{
  border-radius: 7px;
  width: 300px;
  margin: 5px;
  text-align: center;
  padding: 10px 10px;
}

/*----------product list----------*/
.productName{
  font-family: -apple-system;
  font-size: 120%
}

.ProductDetail{  
  font-family: -apple-system;
  font-size: 120%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin: 10%;
}

.nothingInCart{
  font-family: -apple-system;
  font-size: 120%;
  margin-top: 50px;
  text-align: center;
}

.cartSection{
    width: 70%;
}

.shopNow{
  font-size: 80%;
  text-align: center;
  padding: 5px 63px;
  border-radius: 40px; /*修角*/
  background-color: #163766;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.addToCartBtn{
  font-size: 80%;
  text-align: center;
  padding: 3px 5px;
  border-radius: 40px; /*修角*/
  background-color: #163766;
  color: #ffffff;
  cursor: pointer;
}

.input-group-btn{
  cursor: pointer;
  display: inline-flex;
}
.subtractBtn{
  background-color: #1c3455;
  font-weight: bold;
  color: #ffffff;
  border-radius: 10px;
  /* padding: 3px 9px 3px 10px; */
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
}
.addBtn{
  background-color: #1c3455;
  font-weight: bold;
  color: #ffffff;
  border-radius: 10px;
  /* padding: 3px 7px 3px 7px; */
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
}


/*----------check out----------*/
.checkoutTable{
  font-size: 200px;
  width: 100%;
  text-align: left;
}
.checkoutTable tr{
  margin: 10px 10px;
}
.checkoutTable td{
  padding:0px;
  font-size: 20px;
}

.checkoutSection {
  margin-top: initial;
  font-family: -apple-system;
  font-size: 20px;
  text-align:center;
  width: 100%;
}

.checkoutSection button{
  font-size: 18px;
  background-color: #1c3455;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px 10px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}

.grandTotal{
  font-size: 40px;
  font-weight: 700
}

.freeShipping{
  font-weight: bold;
  color: forestgreen;
}

.noShipping{
  font-weight: bold;
  color: rgb(230, 62, 62);
}

/*----------home page----------*/
.MovieHub{
  margin: 10% 20%;
  font-family: -apple-system;
  font-size: 23px;
  text-align:center;
  justify-content: center;
}

/*----------contact----------*/
.contact {
  display: flex;
  flex-direction: column;
  padding: 5px;
  align-items: center;
  margin: 5%;
  font-family: -apple-system;
  font-size: 20px;
  text-align:left; 
}

.contact .form{
  display: inline-grid;
}

.contact .error{
  margin-left: 10px;
  color: red;
  font-size: 80%;
}

.contact input{
  font-family: -apple-system;
  border-radius: 5px;
  display: grid;
  padding: 0.25rem 0;
  margin: 5px;
}

.contact .Message{
  font-family: -apple-system;
  border-radius: 5px;
  text-align:left;
  margin: 5px;
  width: 346px;
  height: 230px;
}

.contactSubmit{
  background-color: #1c3455;
  color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
}

/* -----chatbot CSS----- */
.chatbot{
  margin: 7% 20%;
}

.cs-message-input__tools:first-child .cs-button:last-child {
  margin-right: 0;
  display: none;
}

.cs-message-input__tools:first-child {
  padding: 0.3em;
  color: rgba(0,0,0,.87);
  background-color: #fff;
}

.login-register {
  padding: 20px 40%;
    font-family: -apple-system;
}

.afterLogin{
  margin: 10% 20%;
  font-family: -apple-system;
  font-size: 23px;
  text-align:center;
  justify-content: center;
  color: #ffffff;  
}

.afterLoginSubmit{
  background-color: #1c3455;
  color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  width: 90px;
  font-size: 16px; 
}

.submit{
  background-color: #1c3455;
  color: #ffffff;
  margin: 10px 0px 10px;
  border-radius: 6px;
  cursor: pointer;
  width: 100%  
}

.testLogin{
  margin: 10% 20%;
  font-family: -apple-system;
  font-size: 23px;
  text-align:center;
  justify-content: center;
}

.testLogin .submit{
  background-color: #1c3455;
  color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  width: 90px;
  font-size: 16px;  
}

/*----------media----------*/
@media screen and (max-width: 608px){  
  nav a{
    padding: 10px 8px;
  }

  .container  { 
    display: flex;
  }

  .cartSection{
    width: 100%;
  }

  .moblieCheckOut{
    display: grid;
    text-align: center;
  }

  .chatbot{
    margin: 7%;
  }

  .login-register {
    padding: 20px 10%
  }
}
